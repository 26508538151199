// @flow

import { map } from 'ramda'
import pricingGroups from '../../cottage-pricing-groups'
import type { Season } from '../../../../types'
import { addSeasonIds } from '../../../../helpers'

const winterValue = [
  { priceGroup: pricingGroups.gulf, rate: '5350' },
  { priceGroup: pricingGroups.sun, rate: '4550' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '4000' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '3800' },
  { priceGroup: pricingGroups.dolphin, rate: '3500' },
]

const winterSpringPeak = [
  { priceGroup: pricingGroups.gulf, rate: '5750' },
  { priceGroup: pricingGroups.sun, rate: '4850' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '4250' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '4050' },
  { priceGroup: pricingGroups.dolphin, rate: '3750' },
]

const winterSuperValue = [
  { priceGroup: pricingGroups.gulf, rate: '5250' },
  { priceGroup: pricingGroups.sun, rate: '4150' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '3700' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '3500' },
  { priceGroup: pricingGroups.dolphin, rate: '3100' },
]

export const twentyTwentyTwoWinterSpringBreak: Season[] = map(addSeasonIds, [
  {
    season: 'Winter Holiday',
    dateRange: ['12/18/2021', '1/1/2022'],
    rates: winterSpringPeak,
  },
  {
    season: 'Winter Super Value',
    dateRange: ['1/2/2022', '1/21/2022'],
    rates: winterSuperValue,
  },
  {
    season: 'Winter Value',
    dateRange: ['1/22/2022', '2/18/2022'],
    rates: winterValue,
  },
  {
    season: 'Winter Peak',
    dateRange: ['2/19/2022', '2/25/2022'],
    rates: winterSpringPeak,
  },
  {
    season: 'Winter Value',
    dateRange: ['2/26/2022', '3/11/2022'],
    rates: winterValue,
  },
  {
    season: 'Spring Peak',
    dateRange: ['3/12/2022', '4/22/2022'],
    rates: winterSpringPeak,
  },
])

export default twentyTwentyTwoWinterSpringBreak
