// @flow

import { map } from 'ramda'
import pricingGroups from '../../cottage-pricing-groups'
import type { Season } from '../../../../types'
import { addSeasonIds } from '../../../../helpers'

export const twentyTwentyTwoFall: Season[] = map(addSeasonIds, [
  {
    season: 'Fall Super Value',
    dateRange: ['8/13/2022', '10/07/2022'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '3900' },
      { priceGroup: pricingGroups.sun, rate: '3000' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '2650' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '2450' },
      { priceGroup: pricingGroups.dolphin, rate: '2200' },
    ],
  },
  {
    season: 'Fall Value (Excluding Thanksgiving)',
    dateRange: ['10/08/2022', '12/16/2022'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '4000' },
      { priceGroup: pricingGroups.sun, rate: '3100' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '2750' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '2550' },
      { priceGroup: pricingGroups.dolphin, rate: '2300' },
    ],
  },
  {
    season: 'Fall Holiday',
    dateRange: ['11/19/2022', '11/25/2022'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '4200' },
      { priceGroup: pricingGroups.sun, rate: '3350' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '2950' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '2750' },
      { priceGroup: pricingGroups.dolphin, rate: '2400' },
    ],
  },
])

export default twentyTwentyTwoFall
