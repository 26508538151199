// @flow

type ExpiryStates = {
  expired: 'Expired',
  active: 'Active',
}

const expiryStates = {
  expired: 'Expired',
  active: 'Active',
}

const checkExpiry = (year: Date, states: ExpiryStates) => {
  const currentDate = new Date().getTime()
  const providedDate = new Date(year).getTime()
  const isDateExpired = currentDate >= providedDate
  const { expired, active } = states
  const status = isDateExpired ? expired : active

  return status
}

const setExpiry = (expiryDate: Date): boolean => {
  const { active } = expiryStates
  const expiryStatus = checkExpiry(expiryDate, expiryStates)

  if (expiryStatus === active) {
    return false
  }

  return true
}

export default setExpiry
