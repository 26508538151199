// @flow

import { map } from 'ramda'
import pricingGroups from '../../cottage-pricing-groups'
import type { Season } from '../../../../types'
import { addSeasonIds } from '../../../../helpers'

const springShoulderAndSummerHolidayRates = [
  { priceGroup: pricingGroups.gulf, rate: '4550' },
  { priceGroup: pricingGroups.sun, rate: '3850' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '3350' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '3150' },
  { priceGroup: pricingGroups.dolphin, rate: '2800' },
]

const springSummerShoulderSuperValue = [
  { priceGroup: pricingGroups.gulf, rate: '4000' },
  { priceGroup: pricingGroups.sun, rate: '3200' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '2700' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '2500' },
  { priceGroup: pricingGroups.dolphin, rate: '2100' },
]

const summerValue = [
  { priceGroup: pricingGroups.gulf, rate: '4250' },
  { priceGroup: pricingGroups.sun, rate: '3450' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '2900' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '2700' },
  { priceGroup: pricingGroups.dolphin, rate: '2300' },
]

export const twentyTwentyTwoSpringSummer: Season[] = map(addSeasonIds, [
  {
    season: 'Spring Shoulder',
    dateRange: ['4/23/2022', '4/29/2022'],
    rates: springShoulderAndSummerHolidayRates,
  },
  {
    season: 'Spring/Summer Super Value',
    dateRange: ['4/30/2022', '7/1/2022'],
    rates: springSummerShoulderSuperValue,
  },
  {
    season: 'Summer Holiday',
    dateRange: ['7/2/2022', '7/8/2022'],
    rates: springShoulderAndSummerHolidayRates,
  },
  {
    season: 'Summer Value',
    dateRange: ['7/9/2022', '8/12/2022'],
    rates: summerValue,
  },
])

export default twentyTwentyTwoSpringSummer
