// @flow

export const weeklyRatesExpiryDates: { [string]: Date } = {
  '2020': new Date('01/01/2020'),
  '2021': new Date('01/01/2021'),
  '2022': new Date('01/01/2022'),
  '2023': new Date('01/01/2023'),
  '2024': new Date('01/01/2025'),
}

export default weeklyRatesExpiryDates
