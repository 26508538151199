// @flow

import { map } from 'ramda'
import pricingGroups from '../../cottage-pricing-groups'
import type { RateGroup } from '../../../../types'
import { addSeasonIds } from '../../../../helpers'

const springShoulderSummerHoliday = [
  { priceGroup: pricingGroups.gulf, rate: '690' },
  { priceGroup: pricingGroups.sun, rate: '580' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '500' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '470' },
  { priceGroup: pricingGroups.dolphin, rate: '415' },
]

const nightlyRates2021: RateGroup = {
  id: 'spring-summer-and-fall-2021',
  title: 'Spring, Summer and Fall 2021',
  expiryDate: new Date('01/01/2022'),
  ratesTable: map(addSeasonIds, [
    {
      season: 'Spring Shoulder',
      dateRange: ['4/17/2021', '4/30/2021'],
      rates: springShoulderSummerHoliday,
      expiryDate: new Date('4/30/2021'),
    },
    {
      season: 'Spring/Summer Super Value',
      dateRange: ['5/1/2021', '6/25/2021'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '605' },
        { priceGroup: pricingGroups.sun, rate: '480' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '400' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '365' },
        { priceGroup: pricingGroups.dolphin, rate: '305' },
      ],
    },
    {
      season: 'Summer Holiday',
      dateRange: ['6/26/2021', '7/9/2021'],
      rates: springShoulderSummerHoliday,
    },
    {
      season: 'Summer Value',
      dateRange: ['7/10/2021', '8/6/2021'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '645' },
        { priceGroup: pricingGroups.sun, rate: '515' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '430' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '400' },
        { priceGroup: pricingGroups.dolphin, rate: '335' },
      ],
    },
    {
      season: 'Fall Super Value',
      dateRange: ['8/7/2021', '10/29/2021'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '590' },
        { priceGroup: pricingGroups.sun, rate: '445' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '390' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '360' },
        { priceGroup: pricingGroups.dolphin, rate: '320' },
      ],
    },
    {
      season: 'Fall Value (Excluding Thanksgiving)',
      dateRange: ['10/30/2021', '12/17/2021'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '605' },
        { priceGroup: pricingGroups.sun, rate: '460' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '405' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '375' },
        { priceGroup: pricingGroups.dolphin, rate: '335' },
      ],
    },
    {
      season: 'Fall Holiday',
      dateRange: ['11/20/2021', '11/26/2021'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '635' },
        { priceGroup: pricingGroups.sun, rate: '500' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '440' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '405' },
        { priceGroup: pricingGroups.dolphin, rate: '350' },
      ],
    },
  ]),
}

export default nightlyRates2021
