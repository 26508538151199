// @flow

import type { RateGroup } from '../../../../types'
import { weeklyRatesExpiryDates as expiryDates } from '../weekly-rates-expiry-dates'

import winterSpringBreak2022 from '../seasons/2022-winter-spring-break'
import springSummer2022 from '../seasons/2022-spring-summer'
import fall2022 from '../seasons/2022-fall'

const expiryDate = expiryDates['2023']

export const twentyTwentyTwoWeeklyRates: RateGroup[] = [
  {
    id: 'winter-spring-break-2022',
    title: 'Winter/Spring Break 2022',
    ratesTable: winterSpringBreak2022,
    expiryDate,
  },
  {
    id: 'spring-and-summer-2022',
    title: 'Spring and Summer 2022',
    ratesTable: springSummer2022,
    expiryDate,
  },
  {
    id: 'fall-2022',
    title: 'Fall 2022',
    ratesTable: fall2022,
    expiryDate,
  },
]

export default twentyTwentyTwoWeeklyRates
