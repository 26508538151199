// @flow

import { universalBtoa } from '../../helpers'
import setExpiry from './set-expiry'
import type { Season, RateGroup, RateGroupTransform } from '../../types'

const addSeasonId = (season: Season, index: number) => ({
  id: universalBtoa(`season:${season.season}:${index}`),
  ...season,
})

const addExpiry = (group: RateGroup) => ({
  ...group,
  expired: group.expiryDate ? setExpiry(group.expiryDate) : false,
})

const transformRates = (group: RateGroup): RateGroupTransform => {
  const { id, title } = group
  const { expired }: RateGroup = addExpiry(group)
  const ratesTable: Season[] = group.ratesTable.map(addSeasonId)

  return {
    id,
    title,
    expired,
    ratesTable,
  }
}

export default transformRates
