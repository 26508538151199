// @flow

import type { ComponentType } from 'react'
import styled from 'styled-components'
import { Tr, Th, Td } from 'react-super-responsive-table'
import theme from '../../../../../tailwind'

export const TableHeaderCell: ComponentType<{}> = styled(Th)`
  padding: 1rem;
  border-bottom: 1px solid ${theme.colors.purple};
  font-weight: 700;
  color: ${theme.colors['purple-dark']};

  &:not(:last-child) {
    border-right: 1px solid ${theme.colors.purple};
  }
`

export const TableCell: ComponentType<{}> = styled(Td)`
  padding: ${theme.padding[2]};
  line-height: 1.4;
  color: ${theme.colors['grey-darkest']};

  &:not(:last-child) {
    border-right: 1px solid ${theme.colors['purple-lighter']};
  }
`

export const TableRow: ComponentType<{}> = styled(Tr)`
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.colors['purple-lighter']};
  }
`
