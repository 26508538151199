// @flow

import React from 'react'
import type { Node, Element } from 'react'
import cx from 'classnames'
import { Tbody, Table, Thead, Tr } from 'react-super-responsive-table'
import { renderPrice } from '../../../helpers'
import { weeklyRates, nightlyRates } from '../../../data/rates'
import { fees } from '../../../data/fees'
import { TableHeaderCell, TableCell, TableRow } from './table'
import {
  Heading1,
  Heading2,
  Heading3,
  BodyText,
} from '../../../components/typography'
import './rates-tables.css'
import type { Rate, RateGroup, Season } from '../../../types'

const formattedStandardFee: string = renderPrice(fees.standardFee)

function TableHead() {
  return (
    <Thead>
      <Tr className="bg-purple-lightest text-left">
        <TableHeaderCell className="rounded-sm-tl">Season</TableHeaderCell>
        <TableHeaderCell>Start Date</TableHeaderCell>
        <TableHeaderCell>End Date</TableHeaderCell>
        <TableHeaderCell>Gulf Cottage</TableHeaderCell>
        <TableHeaderCell>Sun Cottage</TableHeaderCell>
        <TableHeaderCell>Mermaid/Coconut</TableHeaderCell>
        <TableHeaderCell>Island/Seahorse</TableHeaderCell>
        <TableHeaderCell className="rounded-sm-tr">Dolphin</TableHeaderCell>
      </Tr>
    </Thead>
  )
}

function RatesGroup({ title, children }: { title: string, children: Node }) {
  return (
    <section>
      <Heading2 className="font-extrabold mb-5 text-black">{title}</Heading2>
      <div
        className={cx(
          'mb-12',
          'rounded',
          'border-purple',
          'border-solid',
          'border',
          'overflow-x-scroll',
          'shadow',
        )}
      >
        <Table>
          <TableHead />
          {children}
        </Table>
      </div>
    </section>
  )
}

const RatesTableCells = ({ rates }: { rates: Rate[] }) =>
  rates.map(({ rate }) => {
    const price = renderPrice(rate)
    return <TableCell key={rate}>{price}</TableCell>
  })

type RenderRatesTableProps = { data: Season[] }
function RenderRatesTable({ data }: RenderRatesTableProps) {
  return (
    <Tbody>
      {data.map(({ id, season, dateRange, rates }) => (
        <TableRow key={id}>
          <TableCell>{season}</TableCell>
          {dateRange.map((date) => (
            <TableCell key={date}>{date}</TableCell>
          ))}
          <RatesTableCells rates={rates} />
        </TableRow>
      ))}
    </Tbody>
  )
}

const filterExpired = ({ expired }) => !expired

type RenderSeasonalRatesProps = { rates: RateGroup[] }
function RenderSeasonalRates({ rates }: RenderSeasonalRatesProps) {
  const activeRates = rates && rates.filter(filterExpired)
  if (activeRates)
    return activeRates.map(({ id, title, ratesTable }) => (
      <RatesGroup key={id} title={title}>
        <RenderRatesTable data={ratesTable} />
      </RatesGroup>
    ))
  return null
}

function WeeklyRatesSection() {
  return (
    <div id="weekly-rates" className="-mx-4 md:-mx-0">
      <section className="mb-8">
        <Heading1 className="text-black mb-2" isTitle>
          Weekly Rates
        </Heading1>
        <BodyText className="text-grey-darker">
          (Call for Monthly Rates)
        </BodyText>
      </section>
      <RenderSeasonalRates rates={weeklyRates} />
    </div>
  )
}

function NightlyRatesSection() {
  return (
    <div id="nightly-rates">
      <div className="mb-8">
        <Heading1 className="text-black mb-2">Nightly Rates</Heading1>
        <BodyText className="text-grey-darker">
          (Min. 4 nights when available)
        </BodyText>
      </div>
      <RenderSeasonalRates rates={nightlyRates} />
    </div>
  )
}

function RatesTables(): Element<'div'> {
  return (
    <div>
      <WeeklyRatesSection />
      <NightlyRatesSection />
      <div className="my-8">
        <Heading3 className="mb-2">Fees (all bookings)</Heading3>
        <BodyText className="text-grey-darkest leading-normal">
          In addition to the rent there are cleaning and administrative fees
          which run {formattedStandardFee} (per booking) depending on the
          cottage. Cleaning and administrative fees are subject to change
          without notice.
        </BodyText>
      </div>
    </div>
  )
}

export default RatesTables
