// @flow

import { map } from 'ramda'
import pricingGroups from '../../cottage-pricing-groups'
import type { RateGroup } from '../../../../types'
import { addSeasonIds } from '../../../../helpers'

const springShoulderSummerHoliday = [
  { priceGroup: pricingGroups.gulf, rate: '760' },
  { priceGroup: pricingGroups.sun, rate: '640' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '555' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '525' },
  { priceGroup: pricingGroups.dolphin, rate: '470' },
]

const summerValue = [
  { priceGroup: pricingGroups.gulf, rate: '705' },
  { priceGroup: pricingGroups.sun, rate: '570' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '485' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '450' },
  { priceGroup: pricingGroups.dolphin, rate: '380' },
]

export const nightlyRates2023: RateGroup = {
  id: 'spring-summer-and-fall-2023',
  title: 'Spring, Summer and Fall 2023',
  expiryDate: new Date('01/01/2024'),
  ratesTable: map(addSeasonIds, [
    {
      season: 'Spring Shoulder',
      dateRange: ['4/15/2023', '4/28/2023'],
      rates: springShoulderSummerHoliday,
    },
    {
      season: 'Spring/Summer Super Value',
      dateRange: ['4/29/2023', '6/16/2023'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '665' },
        { priceGroup: pricingGroups.sun, rate: '530' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '450' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '420' },
        { priceGroup: pricingGroups.dolphin, rate: '350' },
      ],
    },
    {
      season: 'Summer Value',
      dateRange: ['6/17/2023', '6/30/2023'],
      rates: summerValue,
    },
    {
      season: 'Summer Holiday',
      dateRange: ['7/1/2023', '7/7/2023'],
      rates: springShoulderSummerHoliday,
    },
    {
      season: 'Summer Value',
      dateRange: ['7/8/2023', '8/18/2023'],
      rates: summerValue,
    },
    {
      season: 'Fall Super Value',
      dateRange: ['8/19/2023', '10/6/2023'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '650' },
        { priceGroup: pricingGroups.sun, rate: '500' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '445' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '410' },
        { priceGroup: pricingGroups.dolphin, rate: '365' },
      ],
    },
    {
      season: 'Fall Value (Excluding Thanksgiving)',
      dateRange: ['10/7/2023', '12/22/2023'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '665' },
        { priceGroup: pricingGroups.sun, rate: '515' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '460' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '430' },
        { priceGroup: pricingGroups.dolphin, rate: '380' },
      ],
    },
    {
      season: 'Fall Holiday',
      dateRange: ['11/19/2023', '11/25/2023'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '700' },
        { priceGroup: pricingGroups.sun, rate: '555' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '490' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '460' },
        { priceGroup: pricingGroups.dolphin, rate: '395' },
      ],
    },
  ]),
}

export default nightlyRates2023
