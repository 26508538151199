// @flow

import { map } from 'ramda'

import type { RateGroup, RateGroupTransform } from '../../../types'
import transformRates from '../rates-transforms'

import { twentyTwentyTwoWeeklyRates } from './2022'
import { twentyTwentyThreeWeeklyRates } from './2023'

const weeklyRatesData: RateGroup[] = [
  ...twentyTwentyTwoWeeklyRates,
  ...twentyTwentyThreeWeeklyRates,
]

const weeklyRates: RateGroupTransform[] = map(transformRates, weeklyRatesData)

export default weeklyRates
