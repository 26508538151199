// @flow

import type { Node } from 'react'
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import { Heading1, BodyText as P, Heading3 } from '../components/typography'
import {
  RatesTables,
  RatesTermsAndConditions as TermsAndConditions,
} from '../scenes/rates'

type RatesTableStatus = 'Offline' | 'Online'
type RatesPageProps = {
  status: RatesTableStatus,
}

function RatesUpdate() {
  return (
    <div>
      <div className="border-b border-solid border-grey-light pb-2 mb-6">
        <Heading1>Our Rates</Heading1>
      </div>
      <div className="border-b border-solid border-grey-light pb-2 mb-6">
        <Heading3 className="mb-3">Hey, there!</Heading3>
        <P className="mb-4 text-grey-darkest leading-normal">
          We’re in the process of updating our rates, please check back later.
        </P>
      </div>
      <hr />
    </div>
  )
}

function PureRatesPage({ status }: RatesPageProps) {
  return (
    <Layout>
      <div className="bg-white px-8">
        <div className="max-w-xl mx-auto">
          <div className="py-10">
            {status === 'Offline' ? <RatesUpdate /> : <RatesTables />}
            <TermsAndConditions />
          </div>
        </div>
      </div>
    </Layout>
  )
}

function RatesPage(): Node {
  return (
    <StaticQuery
      query={graphql`
        query RatesQuery {
          site {
            siteMetadata {
              page {
                rates {
                  status
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <PureRatesPage status={data.site.siteMetadata.page.rates.status} />
      )}
    />
  )
}

export default RatesPage
