// @flow

import * as React from 'react'
import { Heading1, Heading3, Heading4 } from '../../../components/typography'
import { Heading, Paragraph } from '../../../components/long-form'
import { MediaLink } from '../../../components/links'

type Props = {
  children: React.Node,
}

function InlineHeading({ children }: Props) {
  return <span className="font-bold">{children}: </span>
}

function HeadingTwo({ children }: { children: React.Node }) {
  return (
    <Heading4 className="text-grey-darkest leading-normal font-bold mb-1 underline">
      {children}
    </Heading4>
  )
}

function RatesTermsAndConditions(): React.Element<'div'> {
  return (
    <div className="pt-8">
      <div className="border-b border-solid border-grey-light pb-2 mb-6">
        <Heading1 className="font-bold mb-3">
          Terms &amp; Conditions and Other Important Information
        </Heading1>
        <Heading3 className="text-grey-darkest">
          For Cottages of Paradise Point 436, 452, 454, 460, 462, 464, &amp; 466
          Estero
        </Heading3>
      </div>
      <Paragraph>
        The cottages at 436 Estero, 452 Estero, 454 Estero, 460 Estero, 462
        Estero, 464 Estero, and 466 Estero are all individually owned by Dennis
        & Lisa Greenspon. Collectively the cottages are referred to as either
        Cottages of Paradise Point or “COPP”. Cottages of Paradise Point is
        managed by Tropical Treasures Vacation Rentals LLC. For the purpose of
        this document Tropical Treasures Vacation Rentals, LLC. is herein
        referred to as “Tropical Treasures” or “TTVR, LLC”. Reservations and
        rentals are done through Tropical Treasures. The recipient of the
        following Terms and Conditions is herein referred to as the “guest”.
      </Paragraph>
      <div id="payments-deposits-cancellations-and-refunds">
        <Heading isImportant>
          Payments, Deposits, Cancellations &amp; Refunds
        </Heading>
        <HeadingTwo>
          Reservations Made 180 days or More Prior to Arrival
        </HeadingTwo>
      </div>
      <div id="advance-deposits">
        <Paragraph>
          <InlineHeading>Advance Deposits</InlineHeading>
          Reservations made 180 days or more in advance of arrival require an
          advance deposit of $400 for each week or part thereof reserved at the
          time of booking, with an additional deposit of $400 for each full or
          partial week due 180 days prior to arrival. Advance deposits may be
          made by eCheck, paper check, credit card (Visa or MasterCard),
          cashier’s check, money order or wire transfer.
        </Paragraph>
      </div>
      <div id="final-payments">
        <Paragraph>
          <InlineHeading>Final Payments</InlineHeading>
          Winter/Spring Season (through April) reservations require payment IN
          FULL by eCheck, paper check, cashier’s check, or bank wire transfer 90
          days prior to arrival, or by December 15th, whichever is earlier.
        </Paragraph>
        <Paragraph>
          Summer/Fall Season reservations require payment IN FULL by eCheck,
          paper check, cashier’s check, or bank wire transfer 90 days prior to
          arrival.
        </Paragraph>
      </div>
      <div id="cancellations-and-refunds">
        <Paragraph>
          <InlineHeading>Cancellations &amp; Refunds</InlineHeading>
          All cancellations must be received in writing or email and if eligible
          for a refund are subject to an administrative fee of $200.00 per full
          or partial week reserved.
        </Paragraph>
        <Paragraph>
          Winter/Spring Season (through April) - Advance deposits on
          Winter/Spring Season rentals reserved 180 days or more in advance of
          arrival are refundable less the administrative fee up to 180 days in
          advance of arrival. All payments including final payments become
          non-refundable 90 days prior to arrival or December 15th, whichever is
          earlier.
        </Paragraph>
        <Paragraph>
          Summer/Fall Season - Advance deposits on Summer/Fall rentals reserved
          180 days or more in advance of arrival are refundable less the
          administrative fee up to 180 days in advance of arrival. All payments
          including final payments become non-refundable 90 days prior to
          arrival.
        </Paragraph>
        <HeadingTwo>
          Reservations Made Less than 180 Days Prior to Arrival
        </HeadingTwo>
      </div>
      <div id="advance-deposits-2">
        <Paragraph>
          <InlineHeading>Advance Deposits</InlineHeading>
          Reservations made less than 180 days prior to arrival require an
          advance deposit of $400 for each full or partial week or reserved at
          the time of booking. Advance deposits may be made by eCheck, paper
          check, credit card (Visa or MasterCard), cashier’s check, money order
          or wire transfer.
        </Paragraph>
      </div>
      <div id="final-payments">
        <Paragraph>
          <InlineHeading>Final Payments</InlineHeading>
          Reservations made less than 180 days prior to arrival require payment
          IN FULL by eCheck, paper check, cashier’s check, or bank wire transfer
          90 days prior to arrival.
        </Paragraph>
      </div>
      <div id="cancellations-and-refunds">
        <Paragraph>
          <InlineHeading>Cancellations and Refunds</InlineHeading>
          All cancellations must be received in writing or email and if eligible
          for a refund are subject to an administrative fee of $200.00 per week
          full or partial week reserved.
        </Paragraph>
        <Paragraph>
          Winter/Spring Season (through April ) - Advance deposits and other
          payments on rentals for the Winter/Spring Season reserved less than
          180 days prior to arrival are refundable less the administrative fee
          up to 90 days prior to arrival, or December 15th, whichever is
          earlier. All payments including final payments become non-refundable
          90 days prior to arrival or December 15th, whichever is earlier.
        </Paragraph>
        <Paragraph>
          Summer/Fall Season - Advance deposits and other payments on rentals
          for the Summer/Fall Season reserved less than 180 days prior to
          arrival are refundable less the administrative fee up to 90 days prior
          to arrival. All payments including final payments become
          non-refundable 90 days prior to arrival.
        </Paragraph>
        <HeadingTwo>
          Reservations Made Less than 90 Days Prior to Arrival
        </HeadingTwo>
        <Paragraph>
          Reservations made less than 90 days prior to arrival require
          non-refundable payment in full via eCheck, paper check, cashier’s
          check, or bank wire transfer at time of booking.
        </Paragraph>
        <HeadingTwo>
          Reservations Made 7 days or Less Prior to Arrival
        </HeadingTwo>
        <Paragraph>
          Reservations made within 7 days prior to arrival require
          non-refundable payment in full via credit card, cashier’s check, money
          order, or wire transfer at time of booking.
        </Paragraph>
        <Paragraph>
          When paying by check, please make check payable to TTVR, LLC and mail
          to 16970 San Carlos Blvd., #160-314, Fort Myers, FL 33908.
        </Paragraph>
        <Paragraph>
          Failure to make payment by the due date may result in the cancellation
          of your reservation and will be subject to the “Cancellation & Refund
          Policy”. A $100 charge will be assessed for returned checks. COPP and
          Tropical Treasures reserve the right to modify at any time and the
          Advance Deposit and Payment Policy. A reservation is considered
          confirmed once payment in full has been received and further reflects
          full acknowledgment and acceptance of the terms and conditions
          contained herein.
        </Paragraph>
        <Paragraph>
          Regardless of season, payments on rentals made inside the final due
          date are non-refundable. There are no refunds and/or adjustments to
          the rental rate for late arrivals, early departures, mechanical
          failures, acts of god and violation of any part of the Terms and
          Conditions.
        </Paragraph>
        <Paragraph>
          Longer stays and multiple cottage rentals may have different
          cancellation and refund policies.
        </Paragraph>
      </div>
      <div id="trip-cancellation-insurance">
        <Paragraph>
          <InlineHeading>Trip Cancellation Insurance</InlineHeading>
          For your convenience and protection Tropical Treasures will offer you
          a sense of security by protecting your vacation with the Sun Trip
          Preserver by RedSky Travel Insurance, a leading provider of travel
          insurance to Vacation Rental guests. To learn more about the benefits
          of the Sun Trip Preserver please visit{' '}
          <MediaLink href="http://www.trippreserver.com">
            http://www.trippreserver.com
          </MediaLink>
          . Trip Cancellation Insurance is totally optional on your part. When
          making your reservation you will have the opportunity to add the
          insurance to your reservation if you want.
        </Paragraph>
      </div>
      <div id="security-deposit">
        <Paragraph>
          <InlineHeading>Security Deposit</InlineHeading>
          In lieu of a security deposit, a major credit card (Visa or M/C) must
          be presented prior to arrival and by doing so the card holder
          authorizes Tropical Treasures to charge the account for any additional
          charges, fees and or damages caused by the registered party or their
          guests. Possible charges include but are not limited to, long distance
          telephone charges, extra cleaning, late departure, additional rent,
          damaged or missing items, keys and any other administrative charges
          and or taxes that may be applicable. Under certain circumstance, an
          additional security deposit above and beyond the standard credit card
          requirement may be required. Refund of any such security deposits held
          (less applicable charges) will be in the form of check payable to the
          registered party and will be mailed within 30 days of the departure
          date.
        </Paragraph>
      </div>
      <div id="substitution-of-accommodations">
        <Paragraph>
          <InlineHeading>Substitution of Accommodations</InlineHeading>
          In the event of unforeseen circumstances making a cottage unavailable
          for occupation Cottages of Paradise Point and Tropical Treasures
          reserve the right to substitute accommodations in a like property.
        </Paragraph>
      </div>
      <div id="maximum-occupancy-groups">
        <Paragraph>
          <InlineHeading>Maximum Occupancy/Groups</InlineHeading>
          The maximum occupancy of all COPP cottages is 6, except the Sun
          cottage (464 Estero) which has a maximum occupancy of 8 and the Gulf
          Cottage (466 Estero) which has a maximum occupancy of 7. The
          reservation confirmation reflects the approved maximum occupancy for
          the specific unit reserved and includes small children and infants.
          Any exceptions to this must be noted on the reservation confirmation.
          House parties and/or group gatherings are not allowed. Any guest under
          25 years old must be accompanied by a parent and/or guardian.
          Reservations obtained under false pretenses will result in the
          forfeiture of all funds paid and guests will be asked to vacate the
          rental property immediately and if necessary evicted according to F.
          S. 509.141.
        </Paragraph>
      </div>
      <div id="check-in">
        <Paragraph>
          <InlineHeading>Check-In</InlineHeading>
          Check-in time is after 4:00 PM. Keys will be in a lockbox at the
          cottage and check-in materials will be inside the cottage. On occasion
          check-in may be delayed due to circumstances beyond our control and
          rate adjustments cannot be made for such instances. The lock box
          combination will be provided to you on your final confirmation.
        </Paragraph>
      </div>
      <div id="check-out">
        <Paragraph>
          <InlineHeading>Check-Out</InlineHeading>
          All guests are required to check out NO LATER THAN 10:00 AM in order
          for us to have the appropriate time to clean the property for the next
          arriving guest. Failure to check out by 10:00 AM will result in
          additional charges (a half day’s rent between 10:01 AM and 12:00 PM; a
          full day’s rent if guest vacates after 12:00 PM) or eviction under the
          guide lines of Florida law F. S. 509.141. Upon departure keys are to
          be left at the cottage with one key being left in the lockbox. There
          is a minimum charge of $50.00 for each key not returned.
        </Paragraph>
      </div>
      <div id="reservations">
        <Paragraph>
          <InlineHeading>Reservations</InlineHeading>
          All reservation requests are taken on a first come first serve basis.
          We encourage all guests to reserve the unit/dates of their choice as
          far in advance as reasonably possible. Any guest under 25 years old
          must be accompanied by a parent and/or guardian. Reservations obtained
          under false pretenses will result in the forfeiture of all funds paid
          and guests will be asked to vacate the rental property immediately and
          if necessary evicted according to F. S. 509.141.
        </Paragraph>
      </div>

      <div id="pets">
        <Paragraph>
          <InlineHeading>Pets</InlineHeading>
          COPP has a no-pet policy. Occasional exceptions to that policy might
          be made for dogs, in which case a non-refundable pet fee will be
          charged. Any exceptions to the no-pet policy must be noted in the
          reservation record and confirmation. In the event a guest has a pet in
          a cottage without prior approval the reservation will be considered to
          have been made under false pretenses which will result in forfeiture
          of all funds paid and guests being asked to vacate the rental property
          immediately and if necessary evicted according to F. S. 509.141.
        </Paragraph>
      </div>

      <div id="smoking-policy">
        <Paragraph>
          <InlineHeading>No Smoking</InlineHeading>
          ALL COPP cottages are non-smoking. In the event that a guest smokes in
          a COPP cottage, the reservation will be considered to have been made
          under false pretences and the appropriate action will be taken (see
          “Reservations” above). Furthermore, the guest will be held liable for
          the costs associated with returning the rental property to its
          original condition.
        </Paragraph>
      </div>
      <div id="rules-and-regulations">
        <Paragraph>
          <InlineHeading>Rules and Regulations</InlineHeading>
          All reservations are confirmed with the understanding that all guests
          will abide by the rules and regulations of COPP.
        </Paragraph>
      </div>
      <div id="pool">
        <Paragraph>
          <InlineHeading>Pool</InlineHeading>
          Use of the pool located at 466 Estero is not included in the rent paid
          for your cottage. The owners of the pool have invited all registered
          guests of COPP to use it as their guest. All they ask is that you and
          all members of your party behave responsibly when using the pool,
          follow the pool rules as posted, and sign a waiver. Your invitation to
          use the pool is contingent on signing the waiver. Children must be
          accompanied by an adult at all times.
        </Paragraph>
      </div>
      <div id="parking">
        <Paragraph>
          <InlineHeading>Parking</InlineHeading>
          Each COPP cottage has parking assigned to it. Please use only those
          spaces assigned to your cottage. Parking of RV’s, boats, motor homes,
          campers, trucks, trailers, etc. is prohibited. Any exceptions to this
          must be noted on the reservation confirmation.
        </Paragraph>
      </div>
      <div id="maintenance">
        <Paragraph>
          <InlineHeading>Maintenance</InlineHeading>
          All maintenance items should be reported to Tropical Treasures as soon
          as possible. In the event of equipment malfunction Tropical Treasures
          will expedite repairs through one of our preferred vendors. In the
          event it is determined that the equipment is not in working order due
          to guest misuse, oversight or neglect, the fee for the repair will be
          charged to the guest. No adjustments to rent can be made for
          circumstances beyond our control or for malfunction or loss of use of
          equipment or amenities.
        </Paragraph>
      </div>
      <div id="acts-of-nature">
        <Paragraph>
          <InlineHeading>Acts of Nature</InlineHeading>
          COPP and Tropical Treasures cannot be held liable for any acts of
          nature or occurrences beyond our control. No adjustments to rent will
          be made.
        </Paragraph>
      </div>
      <div id="quiet-use-and-enjoyment">
        <Paragraph>
          <InlineHeading>Quiet Use and Enjoyment</InlineHeading>
          COPP and Tropical treasures cannot guarantee quiet use and enjoyment
          of rental properties due to noise from other persons, neighboring
          properties, construction, maintenance, etc. As these types of events
          are beyond our control, there can be no adjustments to rent for such
          instances.
        </Paragraph>
      </div>
      <div id="furnishings">
        <Paragraph>
          <InlineHeading>Furnishings</InlineHeading>
          COPP and Tropical Treasures do not accept liability for the aesthetic
          or functional perception of the rental property contents and no
          adjustment to rent can be made in such regard.
        </Paragraph>
      </div>
      <div id="entry-of-premises">
        <Paragraph>
          <InlineHeading>Entry of Premises</InlineHeading>
          Guest agrees to allow COPP and Tropical Treasures employees, vendors,
          contractors or subcontractors access to the property for any reason to
          make repairs, improvements, care for or manage the property.
        </Paragraph>
      </div>
      <div id="agency-disclosure">
        <Paragraph>
          <InlineHeading>Agency Disclosure</InlineHeading>
          Tropical Treasures serves as agent and representative of all Cottages
          of Paradise Point cottages and is acting at all times in and for the
          best interest of the owners.
        </Paragraph>
      </div>
      <div id="pest-control">
        <Paragraph>
          <InlineHeading>Pest Control</InlineHeading>
          Cottages of Paradise Point cottages are treated on a scheduled basis
          for pest control. COPP and Tropical Treasures will make every effort
          to address pest control concerns, but is not responsible for
          adjustments to rent due to the presence of pests or in the event of
          untimely service by pest control companies. Some pests are inherent to
          the region and climate making them resilient against professional
          treatment.
        </Paragraph>
      </div>
      <div id="hurricane-policy">
        <Paragraph>
          <InlineHeading>Hurricane Policy</InlineHeading>
          If you are traveling between June and November, please note that this
          is hurricane season. Tropical Treasures encourages guests to purchase
          Trip Cancellation Insurance to protect their vacation investment. COPP
          and Tropical Treasures policy is as follows: Refunds and/or credits
          will be issued ONLY when a “mandatory evacuation” is ordered
          specifically for the area in which COPP is located. The guest will be
          given the opportunity to choose either a credit towards a future stay
          or a refund. The amount of credit or refund offered will equal any
          unused portion of the guest’s reservation. Tropical Treasures standard
          policy for “check-out” will apply in calculating any unused portion of
          a guest reservation. There will be no refunds or adjustments to rent
          for inclement weather unless and not until a mandatory evacuation is
          issued as described above. Refund and/or credit will be issued to the
          registered party within a reasonable period of time.
        </Paragraph>
      </div>
      <div id="student-groups">
        <Paragraph>
          <InlineHeading>Student Groups</InlineHeading>
          Any guest under 25 years old must be accompanied by a parent and/or
          guardian. Reservations obtained under false pretenses will result in
          the forfeiture of all funds paid and guests will be asked to vacate
          the rental property immediately and if necessary evicted according to
          F. S. 509.141.
        </Paragraph>
      </div>
      <div id="accuracy-of-information">
        <Paragraph>
          <InlineHeading>Accuracy of Information</InlineHeading>
          It is to the best of COPP and Tropical Treasures knowledge that the
          information contained on each reservation confirmation as well as on
          the COPP and Tropical Treasures websites is accurate but not
          guaranteed. Descriptions, rates and property amenities listed on this
          confirmation are subject to change without notice. Omissions,
          additions or errors as well as changes in property bedding,
          furnishings, equipment, etc., shall not be the responsibility of COPP
          or Leisure American. Guests are advised to check with Tropical
          Treasures about specific items of importance to them.
        </Paragraph>
      </div>
      <div id="maid-services-special-requests">
        <Paragraph>
          <InlineHeading>Maid Service/Special Requests</InlineHeading>
          Linens, bath towels, and beach towels are provided and have been
          inventoried prior to your arrival. Each property is cleaned and/or
          inspected prior to the arrival of each reservation. Please note that
          additional or daily maid service is not included but may be requested
          and provided for an additional fee. A minimum notice of one business
          day is required. Leisure American does not supply cribs and other
          child care needs. Please ask for the Tropical Treasures preferred
          vendors list for more information.
        </Paragraph>
      </div>
      <div id="telephone-charges">
        <Paragraph>
          <InlineHeading>Telephone Charges</InlineHeading>
          There is no charge for local telephone calls. Long distance calling
          requires a calling card or credit card.
        </Paragraph>
      </div>
      <div id="mail">
        <Paragraph>
          <InlineHeading>Mail</InlineHeading>
          Mail delivery direct to the property is available. Please check with a
          guest service agent for more specific information regarding this
          matter.
        </Paragraph>
      </div>
      <div id="indemnity">
        <Paragraph>
          <InlineHeading>Indemnity</InlineHeading>
          Guest indemnifies Tropical Treasures from and against any and all
          liability, loss damage, and expense, including reasonable attorney
          fees and disbursements arising from injury to persons or damage to
          rental property; occasioned by the failure of the property owner to
          comply with any provision of law or this agreement; occasioned by any
          act or omission of the property owner or any person residing in or
          renting owner’s property; occasioned by any act of omission of the
          property owner, or property owner’s agents, servants, or contractors;
          or, occasioned by any act of omission, with the exception of such acts
          or commissions constituting gross negligence or willful misconduct of
          Leisure American, its agents, servants, or contractors when acting as
          agent for the property owner as provided herein. Special risks may be
          involved when using pools and/or hot tubs and guest assumes all
          responsibility for himself/herself and their guests and for the
          consequences of those at risk. Guest agrees to waive any claim
          whatsoever and hold harmless Cottages of Paradise Point, Tropical
          Treasures, and the property owner for accidents and claims resulting
          from accidents and or injury arising from use of the rental property
          and any of its equipment, porches, decks, stairs, parking area,
          elevators etc.
        </Paragraph>
      </div>
      <div id="damages">
        <Paragraph>
          <InlineHeading>Damages</InlineHeading>
          The guest is responsible for any and all damages that occur during
          their rental period, whether caused by registered party or their
          guest, and agrees to pay for all damages plus court costs and
          reasonable attorney fees incurred in collecting fees for damage.
          Damages include but are not limited to personal property, rent and
          loss of future rent. See “Security Deposit Policy”.
        </Paragraph>
      </div>
    </div>
  )
}

export default RatesTermsAndConditions
