// @flow

import { map } from 'ramda'
import pricingGroups from '../../../cottage-pricing-groups'
import type { Season } from '../../../../../types'
import { addSeasonIds } from '../../../../../helpers'

export const twentyTwentyThreeFall: Season[] = map(addSeasonIds, [
  {
    season: 'Fall Super Value',
    dateRange: ['8/19/2023', '10/6/2023'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '4100' },
      { priceGroup: pricingGroups.sun, rate: '3150' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '2800' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '2600' },
      { priceGroup: pricingGroups.dolphin, rate: '2300' },
    ],
  },
  {
    season: 'Fall Value (Excluding Thanksgiving)',
    dateRange: ['10/7/2023', '12/22/2023'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '4200' },
      { priceGroup: pricingGroups.sun, rate: '3250' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '2900' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '2700' },
      { priceGroup: pricingGroups.dolphin, rate: '2400' },
    ],
  },
  {
    season: 'Fall Holiday',
    dateRange: ['11/19/2023', '11/25/2023'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '4400' },
      { priceGroup: pricingGroups.sun, rate: '3500' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '3100' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '2900' },
      { priceGroup: pricingGroups.dolphin, rate: '2500' },
    ],
  },
])

export default twentyTwentyThreeFall
