// @flow

import { map } from 'ramda'
import pricingGroups from '../../cottage-pricing-groups'
import type { RateGroup } from '../../../../types'
import { addSeasonIds } from '../../../../helpers'

const rates = [
  {
    season: 'Spring Shoulder',
    dateRange: ['4/18/2020', '5/1/2020'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '660' },
      { priceGroup: pricingGroups.sun, rate: '540' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '455' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '430' },
      { priceGroup: pricingGroups.dolphin, rate: '380' },
    ],
  },
  {
    season: 'Spring/Summer Super Value',
    dateRange: ['5/2/2020', '6/26/2020'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '590' },
      { priceGroup: pricingGroups.sun, rate: '460' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '375' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '350' },
      { priceGroup: pricingGroups.dolphin, rate: '295' },
    ],
  },
  {
    season: 'Summer Holiday',
    dateRange: ['6/27/2020', '7/10/2020'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '660' },
      { priceGroup: pricingGroups.sun, rate: '540' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '455' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '430' },
      { priceGroup: pricingGroups.dolphin, rate: '380' },
    ],
  },
  {
    season: 'Summer Value',
    dateRange: ['7/11/2020', '8/7/2020'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '625' },
      { priceGroup: pricingGroups.sun, rate: '500' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '405' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '380' },
      { priceGroup: pricingGroups.dolphin, rate: '325' },
    ],
  },
  {
    season: 'Fall Super Value',
    dateRange: ['8/8/2020', '10/30/2020'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '550' },
      { priceGroup: pricingGroups.sun, rate: '405' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '340' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '315' },
      { priceGroup: pricingGroups.dolphin, rate: '285' },
    ],
  },
  {
    season: 'Fall Value (Except Thanksgiving)',
    dateRange: ['10/31/2020', '12/18/2020'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '570' },
      { priceGroup: pricingGroups.sun, rate: '420' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '355' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '335' },
      { priceGroup: pricingGroups.dolphin, rate: '300' },
    ],
  },
  {
    season: 'Fall Holiday',
    dateRange: ['11/21/2020', '11/27/2020'],
    rates: [
      { priceGroup: pricingGroups.gulf, rate: '600' },
      { priceGroup: pricingGroups.sun, rate: '460' },
      { priceGroup: pricingGroups.coconutAndMermaid, rate: '390' },
      { priceGroup: pricingGroups.seahorseAndIsland, rate: '365' },
      { priceGroup: pricingGroups.dolphin, rate: '315' },
    ],
  },
]

const nightlyRates2020: RateGroup = {
  id: 'spring-summer-and-fall-2020',
  title: 'Spring, Summer and Fall 2020',
  expiryDate: new Date('01/01/2021'),
  ratesTable: map(addSeasonIds, rates),
}

export default nightlyRates2020
