// @flow

import type { RateGroup } from '../../../../types'

import {
  twentyTwentyThreeWinterSpringBreak,
  twentyTwentyThreeSpringSummer,
  twentyTwentyThreeFall,
} from './seasons'

export const twentyTwentyThreeWeeklyRates: RateGroup[] = [
  {
    id: 'winter-spring-break-2023',
    title: 'Winter/Spring Break 2023',
    ratesTable: twentyTwentyThreeWinterSpringBreak,
    expiryDate: new Date('04/31/2023'),
  },
  {
    id: 'spring-and-summer-2023',
    title: 'Spring and Summer 2023',
    ratesTable: twentyTwentyThreeSpringSummer,
    expiryDate: new Date('09/30/2023'),
  },
  {
    id: 'fall-2023',
    title: 'Fall 2023',
    ratesTable: twentyTwentyThreeFall,
    expiryDate: new Date('01/01/2024'),
  },
]

export default twentyTwentyThreeWeeklyRates
