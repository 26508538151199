// @flow

import { map } from 'ramda'

import type { RateGroup, RateGroupTransform } from '../../../types'
import transformRates from '../rates-transforms'
import nightlyRates2020 from './2020'
import nightlyRates2021 from './2021'
import nightlyRates2022 from './2022'
import { nightlyRates2023 } from './2023'

const nightlyRatesData: RateGroup[] = [
  nightlyRates2020,
  nightlyRates2021,
  nightlyRates2022,
  nightlyRates2023,
]

const nightlyRates: RateGroupTransform[] = map(transformRates, nightlyRatesData)

export default nightlyRates
