// @flow

import { map } from 'ramda'
import pricingGroups from '../../../cottage-pricing-groups'
import type { Season } from '../../../../../types'
import { addSeasonIds } from '../../../../../helpers'

const springShoulderAndSummerHolidayRates = [
  { priceGroup: pricingGroups.gulf, rate: '4800' },
  { priceGroup: pricingGroups.sun, rate: '4050' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '3500' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '3300' },
  { priceGroup: pricingGroups.dolphin, rate: '2950' },
]

const springSummerShoulderSuperValue = [
  { priceGroup: pricingGroups.gulf, rate: '4200' },
  { priceGroup: pricingGroups.sun, rate: '3350' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '2850' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '2650' },
  { priceGroup: pricingGroups.dolphin, rate: '2200' },
]

const summerValue = [
  { priceGroup: pricingGroups.gulf, rate: '4450' },
  { priceGroup: pricingGroups.sun, rate: '3600' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '3050' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '2850' },
  { priceGroup: pricingGroups.dolphin, rate: '2400' },
]

export const twentyTwentyThreeSpringSummer: Season[] = map(addSeasonIds, [
  {
    season: 'Spring Shoulder',
    dateRange: ['4/15/2023', '4/28/2023'],
    rates: springShoulderAndSummerHolidayRates,
  },
  {
    season: 'Spring/Summer Super Value',
    dateRange: ['4/29/2023', '6/16/2023'],
    rates: springSummerShoulderSuperValue,
  },
  {
    season: 'Summer Value',
    dateRange: ['6/17/2023', '6/30/2023'],
    rates: summerValue,
  },
  {
    season: 'Summer Holiday',
    dateRange: ['7/1/2023', '7/7/2023'],
    rates: springShoulderAndSummerHolidayRates,
  },
  {
    season: 'Summer Value',
    dateRange: ['7/8/2023', '8/18/2023'],
    rates: summerValue,
  },
])

export default twentyTwentyThreeSpringSummer
