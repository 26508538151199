// @flow

import { map } from 'ramda'
import pricingGroups from '../../../cottage-pricing-groups'
import type { Season } from '../../../../../types'
import { addSeasonIds } from '../../../../../helpers'

const winterValue = [
  { priceGroup: pricingGroups.gulf, rate: '5500' },
  { priceGroup: pricingGroups.sun, rate: '4800' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '4200' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '4000' },
  { priceGroup: pricingGroups.dolphin, rate: '3700' },
]

const winterSpringPeak = [
  { priceGroup: pricingGroups.gulf, rate: '5950' },
  { priceGroup: pricingGroups.sun, rate: '5100' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '4450' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '4250' },
  { priceGroup: pricingGroups.dolphin, rate: '3950' },
]

export const twentyTwentyThreeWinterSpringBreak: Season[] = map(addSeasonIds, [
  {
    season: 'Winter Holiday',
    dateRange: ['12/17/2022', '1/6/2023'],
    rates: winterSpringPeak,
  },
  {
    season: 'Winter Value',
    dateRange: ['1/7/2023', '2/17/2023'],
    rates: winterValue,
  },
  {
    season: 'Winter Peak',
    dateRange: ['2/18/2023', '2/24/2023'],
    rates: winterSpringPeak,
  },
  {
    season: 'Winter Value',
    dateRange: ['2/25/2023', '3/3/2023'],
    rates: winterValue,
  },
  {
    season: 'Spring Peak',
    dateRange: ['3/4/2023', '4/14/2023'],
    rates: winterSpringPeak,
  },
])

export default twentyTwentyThreeWinterSpringBreak
