// @flow

const cottagePricingGroups = {
  gulf: 'Gulf',
  sun: 'Sun',
  coconutAndMermaid: 'Coconut and Mermaid',
  seahorseAndIsland: 'Seahorse and Island',
  dolphin: 'Dolphin',
}

export default cottagePricingGroups
