// @flow

import { map } from 'ramda'
import pricingGroups from '../../cottage-pricing-groups'
import type { RateGroup } from '../../../../types'
import { addSeasonIds } from '../../../../helpers'

const springShoulderSummerHoliday = [
  { priceGroup: pricingGroups.gulf, rate: '725' },
  { priceGroup: pricingGroups.sun, rate: '610' },
  { priceGroup: pricingGroups.coconutAndMermaid, rate: '530' },
  { priceGroup: pricingGroups.seahorseAndIsland, rate: '500' },
  { priceGroup: pricingGroups.dolphin, rate: '445' },
]

const nightlyRates2022: RateGroup = {
  id: 'spring-summer-and-fall-2022',
  title: 'Spring, Summer and Fall 2022',
  expiryDate: new Date('01/01/2023'),
  ratesTable: map(addSeasonIds, [
    {
      season: 'Spring Shoulder',
      dateRange: ['4/23/2022', '4/29/2022'],
      rates: springShoulderSummerHoliday,
    },
    {
      season: 'Spring/Summer Super Value',
      dateRange: ['4/30/2022', '7/1/2022'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '635' },
        { priceGroup: pricingGroups.sun, rate: '510' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '430' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '395' },
        { priceGroup: pricingGroups.dolphin, rate: '335' },
      ],
    },
    {
      season: 'Summer Holiday',
      dateRange: ['7/2/2022', '7/8/2022'],
      rates: springShoulderSummerHoliday,
    },
    {
      season: 'Summer Value',
      dateRange: ['7/9/2022', '8/12/2022'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '675' },
        { priceGroup: pricingGroups.sun, rate: '550' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '460' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '430' },
        { priceGroup: pricingGroups.dolphin, rate: '365' },
      ],
    },
    {
      season: 'Fall Super Value',
      dateRange: ['8/13/2022', '10/07/2022'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '620' },
        { priceGroup: pricingGroups.sun, rate: '475' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '420' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '390' },
        { priceGroup: pricingGroups.dolphin, rate: '350' },
      ],
    },
    {
      season: 'Fall Value (Excluding Thanksgiving)',
      dateRange: ['10/8/2022', '12/16/2022'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '635' },
        { priceGroup: pricingGroups.sun, rate: '495' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '435' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '405' },
        { priceGroup: pricingGroups.dolphin, rate: '365' },
      ],
    },
    {
      season: 'Fall Holiday',
      dateRange: ['11/19/2022', '11/25/2022'],
      rates: [
        { priceGroup: pricingGroups.gulf, rate: '665' },
        { priceGroup: pricingGroups.sun, rate: '530' },
        { priceGroup: pricingGroups.coconutAndMermaid, rate: '470' },
        { priceGroup: pricingGroups.seahorseAndIsland, rate: '435' },
        { priceGroup: pricingGroups.dolphin, rate: '380' },
      ],
    },
  ]),
}

export default nightlyRates2022
